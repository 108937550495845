@font-face {
  font-family: "Gibson SemiBold";
  src: local("Gibson SemiBold"),
    url(./assets/fonts/Gibson-SemiBold.otf) format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Maven Pro", "Arial", sans-serif;
}
